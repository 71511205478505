import { Injectable, signal } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GlobalLoadingIndicatorService {
  readonly isLoading = signal(false);
  readonly isHideContent = signal(false);

  showLoading(hideContent = false) {
    this.isLoading.set(true);
    if (hideContent) {
      this.isHideContent.set(true);
    }
  }

  hideLoading() {
    this.isLoading.set(false);
    this.isHideContent.set(false);
  }
}
